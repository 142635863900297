import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, MenuItem, Select,InputLabel, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { tokens } from "../../theme";

const InvoicesAvcXOns = () => {

  const [valores, setValores] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => 2021 + index);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";

  const [selectedPeriod, setSelectedPeriod] = useState(`0`);

  const handleUpload = (e) => {

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('file', file);
        setLoading(true);
        fetch(`${apiHost}/service/avc/upload?cnpj=24870139000405`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
          body: formData
        })
        .then(response => {
          if (response.status === 401) {
            alert('Sessão expirada. Por favor, faça login novamente.');
            navigate('/');
            return null;
          }
          return response.json();
        }).then(data => {
          setValores(data.avcValuesDtos.map(item => item));
        })
          .catch(error => {
            console.error('Erro ao enviar arquivo:', error);
          }).finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handleUploadOns = () => {

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {

        setLoading(true);
        const partes = selectedPeriod.split('-');
        const apuracao = `${partes[0]}${partes[1]}`;
        fetch(`${apiHost}/service/avc/avc-ons?apuracao=${apuracao}`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          }).then(data => {
            setValores(data.avcValuesDtos.map(item => item));
          })
          .catch(error => {
            console.error('Erro ao enviar arquivo:', error);
          }).finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const handlePeriodChange = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
       
        const period = e.target.value;
        setSelectedPeriod(period);
        localStorage.setItem('setSelectedPeriod', period);


        const partes = e.target.value.split('-');
        setLoading(true);
        fetch(`${apiHost}/service/avc?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&status`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setValores(data.avcValuesDtos.map(item => item));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const fileInputRef = useRef(null);

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };


  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {

    const storedPeriod = localStorage.getItem('setSelectedPeriod');
    if (storedPeriod) setSelectedPeriod(storedPeriod);

    const fetchInitialData = (user) => {
      const periodParts = storedPeriod ? storedPeriod.split('-') : [`${new Date().getFullYear()}`, `${new Date().getMonth() + 1}`];
     
      setLoading(true);
      fetch(`${apiHost}/service/avc?ano=${periodParts[0]}&mes=${periodParts[1]}&numeroOns=1257&status`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return null;
        }
        return response.json();
      })
      .then(data => {
           setValores(data.avcValuesDtos.map(item => item));
      })
      .catch(error => console.error(error))
      .finally(() => {
        setLoading(false);
      });
    };

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (user && (user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTO'))) {
        fetchInitialData(user);
      } else {
        navigate(`/404`);
      }
    }
  }, [apiHost, navigate, secretKey]);


  const columns = [
    { field: "idUsuaria", headerName: "Codigo Ons" },
    {
      field: "transmissoras",
      headerName: "Usuário",
      flex: 1.5,
      cellClassName: "name-column--cell",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.2,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5')}
        </Typography>
      ),
    },

    {
      field: "totalImpostoAvc",
      headerName: "Impostos AVC",
      flex: 1.5,
      renderCell: (params) => (
        <Box>
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalImpostoAvc !== null ? params.row.totalImpostoAvc.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>       
        </Box>
      ),
    },   
    {
      field: "totalImpostoOns",
      headerName: "Impostos ONS",
      flex: 1.5,
      renderCell: (params) => (
        <Box>
        <Typography color={colors.blueAccent[500]}>
          {params.row.totalImpostoOns !== null ? params.row.totalImpostoOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
        </Box>
      ),
    },   

    {
      field: "totalSemImpostoAvc",
      headerName: "S/ Impostos AVC",
      flex: 1.5,
      renderCell: (params) => (
        <Box>
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalSemImpostoAvc !== null ? params.row.totalSemImpostoAvc.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
        </Box>
      ),
    },
    {
      field: "totalSemImpostoOns",
      headerName: "S/ Impostos ONS",
      flex: 1.5,
      renderCell: (params) => (
        <Box>
        <Typography color={colors.blueAccent[500]}>
          {params.row.totalSemImpostoOns !== null ? params.row.totalSemImpostoOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
        </Box>
      ),
    },
    {
      field: "totalAvc",
      headerName: "Total AVC ",
      flex: 1,
      renderCell: (params) => (
        <Box>
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalAvc !== null ?  params.row.totalAvc.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }): (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
        </Box>
      ),
    },  
    {
      field: "totalOns",
      headerName: "Total ONS ",
      flex: 1,
      renderCell: (params) => (
        <Box>
        <Typography color={colors.blueAccent[500]}>
          {params.row.totalOns !== null ? params.row.totalOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
        </Box>
      ),
    },  
    
    {
      field: "origem",
      headerName: "Origem",
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.origem}
        </Typography>
      ),
    }

  ];

  return (
    <Box m="30px">
     <Grid container spacing={4}>
        <Grid item xs={3}>
          <Header title="AVC X API ONS " subtitle="Comparação AVC x API ONS" />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="period-label">Período Contábil</InputLabel>
            <Select
              label="Período Contábil"
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={handlePeriodChange}
            >
              <MenuItem value="0">Período Contábil ...</MenuItem>
              {years.slice().sort((a, b) => b - a).map(year => (
                months.map(month => (
                  <MenuItem key={`${year}-${month.value}`} value={`${year}-${month.value}`}>
                    {`${month.label} ${year}`}
                  </MenuItem>
                ))
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={3}>
        <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",

              }}
              onClick={handleClickUpload}
            >
              <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUpload} />
              Upload AVC Excel
            </Button>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleUpload}
            />
        </Grid>
        <Grid item xs={3}>
        <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",
              }}
              onClick={handleUploadOns}
            >
              <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUploadOns} />
              Upload AVC ONS
            </Button>
        </Grid>
      </Grid>
      
      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={valores}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box >
  );
};

export default InvoicesAvcXOns;
