import UploadFileOutlined from "@mui/icons-material/UploadFileOutlined";
import { Backdrop, Box, Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography, useTheme } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CryptoJS from 'crypto-js';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { tokens } from "../../theme";


const useRefreshEffect = (refresh) => {
  useEffect(() => {
    if (refresh) {
      window.location.reload();
    }
  }, [refresh]);
};

const Unidades = () => {
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => 2021 + index);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  useRefreshEffect(refresh);

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];
  const [selectedPeriod, setSelectedPeriod] = useState(`0`);

  const handlePeriodChange = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('FINANCEIRO')
          || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
      }
      else {
        const period = e.target.value;
        setSelectedPeriod(period);
        localStorage.setItem('setSelectedPeriod', period);

        const partes = period.split('-');

        setLoading(true);

        fetch(`${apiHost}/service/extrato?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&cnpj=24870139000405`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => setData(data))
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false)
          });
      }
    }
  };


  const handleUpload = async (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (!user || !user.profiles ||
        !(user.profiles.includes('ADMIN') || user.profiles.includes('FINANCEIRO') || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
        return;
      }

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);

      const partes = selectedPeriod.split('-');

      try {
        const response = await fetch(`${apiHost}/service/extrato/upload?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&cnpj=24870139000405`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
          body: formData
        });

        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return;
        }

        if (!response.ok) {
          throw new Error("Erro ao processar o arquivo.");
        }

        // Criar Blob para download do arquivo
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name + '.ofx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setTimeout(() => setRefresh(true), 1000);

      } catch (error) {
        console.error("Erro no upload:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleUploadOFX = async (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (!user || !user.profiles ||
        !(user.profiles.includes('ADMIN') || user.profiles.includes('FINANCEIRO') || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
        return;
      }

      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);

      try {
        const response = await fetch(`${apiHost}/service/extrato/ofx-generation/zip`, {
          method: 'POST',
          headers: {
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
          body: formData
        });

        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return;
        }

        if (!response.ok) {
          throw new Error("Erro ao processar o arquivo.");
        }

        // Criar Blob para download do arquivo
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.name + '.ofx';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        setTimeout(() => setRefresh(true), 1000);

      } catch (error) {
        console.error("Erro no upload:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleCompute = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('FINANCEIRO')
          || user.profiles.includes('COBRANCA'))) {
        navigate(`/404`);
      }
      else {
        setLoading(true);
        const partes = selectedPeriod.split('-');

        fetch(`${apiHost}/service/extrato/compute?ano=${partes[0]}&mes=${partes[1]}&numeroOns=1257&cnpj=24870139000405`, {
          method: 'POST',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => setData(data))
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false)
          });
      }
    }
  };

  const fileInputRef = useRef(null);
  const fileInputRefOFX = useRef(null);

  const handleClickUpload = () => {
    fileInputRef.current.click();
  };

  const handleClickUploadOFX = () => {
    fileInputRefOFX.current.click();
  };


  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    const storedPeriod = localStorage.getItem('setSelectedPeriod');
    if (storedPeriod) setSelectedPeriod(storedPeriod);

    const fetchInitialData = (user) => {
      const periodParts = storedPeriod ? storedPeriod.split('-') : [`${new Date().getFullYear()}`, `${new Date().getMonth() + 1}`];

      setLoading(true);
      fetch(`${apiHost}/service/extrato?ano=${periodParts[0]}&mes=${periodParts[1]}&numeroOns=1257&cnpj=24870139000405`, {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`,
          'Access-Control-Allow-Origin': '*'
        },
      })
        .then(response => {
          if (response.status === 401) {
            alert('Sessão expirada. Por favor, faça login novamente.');
            navigate('/');
            return null;
          }
          setLoading(false);
          return response.json();
        })
        .then(data => setData(data))
        .catch(error => console.error(error))
        .finally(() => {
          setLoading(false)
        });
    };
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (user && (user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTO'))) {
        fetchInitialData(user);
      } else {
        navigate(`/404`);
      }
    }
  }, [apiHost, navigate, secretKey]);

  const columns = [
    { field: "periodo", headerName: "Periodo", flex: 2, },
    { field: "agencia", headerName: "Agencia", flex: 1, },
    { field: "conta", headerName: "Conta", flex: 1, },
    {
      field: "cnpj", headerName: "Cnpj", flex: 1.5, renderCell: (params) => (
        <Typography >
          {params.row.cnpj ? params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5') : ""}
        </Typography>
      ),
    },
    { field: "historico", headerName: "Historico", type: "text", headerAlign: "left", align: "left", flex: 3 },
    { field: "documento", headerName: "Documento", flex: 1 },
    {
      field: "action",
      headerName: "Valor",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.valor < 0 ? 'red' : 'green'}>
          {params.row.valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    }
    ,];


  return (
    <Box m="20px">
      <Grid container spacing={4}>
        <Grid item xs={3}>
          <Header
            title="Extratos"
            subtitle="Lista de lançamentos"
          />
        </Grid>
        <Grid item xs={3}>
          <FormControl fullWidth>
            <InputLabel id="period-label">Período Contábil</InputLabel>
            <Select
              label="Período Contábil"
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={handlePeriodChange}
            >
              <MenuItem value="0">Período Contábil ...</MenuItem>
              {years.slice().sort((a, b) => b - a).map(year => (
                months.map(month => (
                  <MenuItem key={`${year}-${month.value}`} value={`${year}-${month.value}`}>
                    {`${month.label} ${year}`}
                  </MenuItem>
                ))
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",

            }}
            onClick={handleClickUpload}
          >
            <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUpload} />
            Upload Extrato
          </Button>
          <input
            type="file"
            ref={fileInputRef}
            style={{ display: 'none' }}
            onChange={handleUpload}
          />
        </Grid>
        <Grid item xs={2}>
        <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",

            }}
            onClick={handleClickUploadOFX}
          >
            <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUploadOFX} />
            Baixar OFX
          </Button>
          <input
            type="file"
            ref={fileInputRefOFX}
            style={{ display: 'none' }}
            onChange={handleUploadOFX}
          />
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",

            }}
            onClick={handleCompute}
          >
            Computar Extrato
          </Button>
        </Grid>
      </Grid>

      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <div>

          <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        </div>
        <DataGrid
          rows={data}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Unidades;
