import React, { useEffect, useState } from 'react';
import { Box, Button, useTheme, Backdrop, CircularProgress, Typography, Grid } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { OverviewTasksProgress } from '../../components/OverviewTasksProgress';
import { OverviewTotalProfit } from '../../components/OverviewTotalProfit';
import { useLocation, useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js';


const UsuariosDetail = () => {
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const [valores, setValores] = useState([]);
  const [data, setData] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();


  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const cnpj = searchParams.get('cnpj');



  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };
  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };


  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN'))) {
        navigate(`/404`);
      }
      else {
        setLoading(true);
        fetch(`${apiHost}/service/avc/unidade?cnpj=${cnpj}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          },
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setData(data);
            setValores(data.avcValuesDtos.map(item => ({ ...item, id: `${item.numeroAvc}-${item.id}` })));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }

  }, [apiHost, cnpj, navigate]);



  const columns = [
    { field: "numeroAvc", headerName: "Codigo AVC" },
    {
      field: "transmissoras",
      headerName: "Usuários",
      flex: 2,
      cellClassName: "name-column--cell",

    },
    {
      field: "periodoContabilInt",
      headerName: "Periodo Contabil",
      flex: 1,
      cellClassName: "name-column--cell",

    },

    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5')}
        </Typography>
      ),
    },
    {
      field: "valorParcela1",
      headerName: "Dia 15",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.status1 === 'Aguardando' ? colors.blueAccent[500] : params.row.status1 === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
          {params.row.valorParcela1.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "valorParcela2",
      headerName: "Dia 25",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.status2 === 'Aguardando' ? colors.blueAccent[500] : params.row.status2 === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
          {params.row.valorParcela2.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "valorParcela3",
      headerName: "Dia 05",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.status3 === 'Aguardando' ? colors.blueAccent[500] : params.row.status3 === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
          {params.row.valorParcela3.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status Faturamento",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.row.status === 'Aguardando' ? "warning" : params.row.status === 'Atraso' ? "error" : "success"}

        >
          {params.row.status}
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Header title="Faturamento AVC" subtitle="Lista de Valores a Receber" />
      <div>
        <Box display="flex" alignItems="center">

          <Box mr={2}>
            <Grid
              xs={6}
              sm={3}
              lg={1}

            >
              <OverviewTasksProgress
                sx={{ height: '100%' }}
                value={typeof data.totalRecebidoPerCent === 'number' ? data.totalRecebidoPerCent.toFixed(2) : 0}
                title="Recebido"
              />

            </Grid>
          </Box>
          <Box mr={2}>
            <Grid
              xs={6}
              sm={3}
              lg={1}
            >
              <OverviewTasksProgress
                sx={{ height: '100%' }}
                value={typeof data.totalAtrasoPercent === 'number' ? data.totalAtrasoPercent.toFixed(2) : 0}
                title="Atrasado"
              />
            </Grid>
          </Box>
          <Box mr={2}>
            <Grid
              xs={6}
              sm={3}
              lg={1}
            >
              <OverviewTasksProgress
                sx={{ height: '100%' }}
                value={typeof data.totalAguardandoPercent === 'number' ? data.totalAguardandoPercent.toFixed(2) : 0}
                title="Aguardando"
              />
            </Grid>
          </Box>
          <Box mr={2}>
            <Grid
              xs={6}
              sm={3}
              lg={1}
            >
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value={typeof data.totalEmitido === 'number' ? data.totalEmitido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0.0"}
                title="Total Emitido"
              />
            </Grid>
          </Box>
          <Box mr={2}>
            <Grid
              xs={6}
              sm={3}
              lg={1}
            >
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value={typeof data.totalRecebido === 'number' ? data.totalRecebido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0.0"}
                title="Recebido"
                color="#fff"
              />
            </Grid>
          </Box>
          <Box mr={2}>
            <Grid
              xs={6}
              sm={3}
              lg={1}
            >
              <OverviewTotalProfit
                sx={{ height: '100%' }}
                value={typeof data.totalAguardando === 'number' ? data.totalAguardando.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : "R$ 0.0"}
                title="A Receber"
                color="#fff"
              />
            </Grid>
          </Box>
        </Box>
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </div>


      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={valores}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box >
  );
};

export default UsuariosDetail;
