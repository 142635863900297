import {
  Backdrop, Box, Button, CircularProgress, FormControl,
  Grid, MenuItem, Select, Typography, useTheme
} from "@mui/material";
import { DeleteForeverOutlined, DownloadingOutlined, CloudSyncRounded } from '@mui/icons-material';
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import CryptoJS from 'crypto-js';
import { useEffect, useState } from 'react';
import { FaRegCheckCircle } from "react-icons/fa";
import { IoIosHourglass } from "react-icons/io";
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { OverviewTasksProgressTotal } from "../../components/OverviewTasksProgressTotal";
import { tokens } from "../../theme";
import { format } from 'date-fns';


const ArquivosSite = () => {
  const [enabledButton, setEnabledButton] = useState(false);
  const [hasInvoice, setHasInvoice] = useState({ hasInvoice: 0, hasInvoicePdf: 0, hasNoInvoice: 0, hasInvoiceXml: 0, hasInvoiceTicket: 0 });
  const [itemsWithRedeBasica, setItemsWithRedeBasica] = useState(0);
  const [valores, setValores] = useState([]);
  const [progress, setProgress] = useState("0 %");
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => 2021 + index);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();

  const handleClick = (item) => {
    navigate(`/arquivo-detail?ano=${item.ano}&numeroAvc=${item.numeroAvc}&mes=${item.mes}&companyIdentify=${item.cnpj}&id=${item.idUsuaria}`);
  };

  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const apiHostArq = process.env.REACT_APP_API_URL_ARQ || "http://localhost:8082";
  const ownerCompany = process.env.REACT_APP_API_URL_COMPANY_CNPJ || "24870139000405";
  const onsCode = process.env.REACT_APP_API_URL_COMPANY_ONS_CODE || "1257";

  const [selectedPeriod, setSelectedPeriod] = useState("0");
  const [selectedStatus, setSelectedStatus] = useState("TODOS");

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const statusCarga = [
    { value: 'TODOS', label: 'Todos' },
    { value: 'SEMBOLETOS', label: 'Aguardando Boleto' },
    { value: 'SEMDANFE', label: 'Aguardando DANFE' },
    { value: 'SEMXML', label: 'Aguardando XML' }
  ];

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const handlePeriodChange = (e) => {

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      }
      else {
        const period = e.target.value;
        setSelectedPeriod(period);
        localStorage.setItem('setSelectedPeriod', period);

        setEnabledButton(true);
        const partes = period.split('-');
        setLoading(true);
        fetch(`${apiHost}/service/avc?ano=${partes[0]}&mes=${partes[1]}&numeroOns=${onsCode}&status`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json()
          }).then(data => {
            setValores(data.avcValuesDtos.filter(item =>
              (selectedStatus === 'SEMBOLETOS' && (!item.invoice || !item.invoice.ticketDtoList)) ||
              (selectedStatus === 'SEMXML' && (!item.invoice || !item.invoice.nfeDto || !item.invoice.nfeDto.hasXml)) ||
              (selectedStatus === 'SEMDANFE' && (!item.invoice || !item.invoice.nfeDto || !item.invoice.nfeDto.hasPdf)) ||
              (selectedStatus === 'TODOS' && item)))

            const hasInvoice = data.avcValuesDtos.reduce((acc, item) => {
              if (item.totalComPisPasep > 0 && item.invoice && item.invoice.nfeDto
                && item.invoice.nfeDto.hasXml) {
                acc.hasInvoiceXml++;
                item.hasInvoiceXml = true
              }

              if (item.totalComPisPasep > 0 && item.invoice && item.invoice.nfeDto
                && item.invoice.nfeDto.hasPdf) {
                acc.hasInvoicePdf++;
                item.hasInvoicePdf = true
              }

              if (item.totalComPisPasep > 0 && item.invoice && item.invoice.ticketDtoList) {
                acc.hasInvoiceTicket++;
                item.hasInvoicePdf = true
              }

              if (item.totalComPisPasep > 0 && item.invoice) {
                acc.hasInvoice++;
              }
              else if (item.totalComPisPasep > 0) {
                acc.hasNoInvoice++;
              }
              return acc;
            }, { hasInvoicePdf: 0, hasNoInvoice: 0, hasInvoiceXml: 0, hasInvoiceTicket: 0, hasInvoice: 0 });
            setHasInvoice(hasInvoice);
            setItemsWithRedeBasica(data.avcValuesDtos.filter(item => item.totalComPisPasep > 0).length);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false)
          });
      }
    }
  };

  const handleSyncAvc = async (event) => {
    event.preventDefault();
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (!user || !user.profiles || !(user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      } else {
        const partes = selectedPeriod.split('-');
        setLoading(true);
        try {
          var response = await fetch(`${apiHostArq}/arquivos/organizer/v1?cnpj=${ownerCompany}&ano=${partes[0]}&mes=${partes[1]}&onsCode=${onsCode}`,
            {
              method: 'POST',
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                'Access-Control-Allow-Origin': '*'
              }
            })

          if (!response.ok) {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return;
            } else {
              alert('Erro ao gerar o arquivo. Por favor, tente novamente.');
              return;
            }
          }

          const taskId = await response.json();

          const checkTaskStatus = async () => {
            const statusResponse = await fetch(`${apiHostArq}/job/status?id=${taskId}`, {
              headers: {
                "Authorization": `Bearer ${user.token}`,
              },
            });

            if (!statusResponse.ok) {
              throw new Error('Erro ao verificar o status da tarefa.');
            }

            const { status, progress } = await statusResponse.json();

            if (status === 'FINALIZADO') {
              setLoading(false);
              setProgress(`100 %`);
              navigate('/arquivos-sistema');
            } else if (status === 'FALHA') {
              alert('Erro ao gerar o arquivo. Por favor, tente novamente.');
              setLoading(false);
            } else {
              console.log(progress)
              setProgress(`${progress} %`);
              setTimeout(checkTaskStatus, 5000);
            }
          };
          checkTaskStatus();
        } catch (error) {
          alert('Erro ao processar o download. Por favor, tente novamente.');
          setLoading(false);
        }
      }
    }
  };

  const handleStatusChange = (e) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      }
      else {
        const statusFile = e.target.value;
        localStorage.setItem('setSelectedStatusFile', statusFile);
        setSelectedStatus(statusFile);        

        setEnabledButton(true);
        const partes = selectedPeriod.split('-');
        setLoading(true);
        fetch(`${apiHost}/service/avc?ano=${partes[0]}&mes=${partes[1]}&numeroOns=${onsCode}&status`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json()
          }).then(data => {
            setValores(data.avcValuesDtos.filter(item =>
              (statusFile === 'SEMBOLETOS' && (!item.invoice || !item.invoice.ticketDtoList)) ||
              (statusFile === 'SEMXML' && (!item.invoice || !item.invoice.nfeDto || !item.invoice.nfeDto.hasXml)) ||
              (statusFile === 'SEMDANFE' && (!item.invoice || !item.invoice.nfeDto || !item.invoice.nfeDto.hasPdf)) ||
              (statusFile === 'TODOS' && item)))

            const hasInvoice = data.avcValuesDtos.reduce((acc, item) => {
              if (item.totalComPisPasep > 0 && item.invoice && item.invoice.nfeDto
                && item.invoice.nfeDto.hasXml) {
                acc.hasInvoiceXml++;
                item.hasInvoiceXml = true
              }

              if (item.totalComPisPasep > 0 && item.invoice && item.invoice.nfeDto
                && item.invoice.nfeDto.hasPdf) {
                acc.hasInvoicePdf++;
                item.hasInvoicePdf = true
              }

              if (item.totalComPisPasep > 0 && item.invoice && item.invoice.ticketDtoList) {
                acc.hasInvoiceTicket++;
                item.hasInvoicePdf = true
              }

              if (item.totalComPisPasep > 0 && item.invoice) {
                acc.hasInvoice++;
              }
              else if (item.totalComPisPasep > 0) {
                acc.hasNoInvoice++;
              }
              return acc;
            }, { hasInvoicePdf: 0, hasNoInvoice: 0, hasInvoiceXml: 0, hasInvoiceTicket: 0, hasInvoice: 0 });
            setHasInvoice(hasInvoice);
            setItemsWithRedeBasica(data.avcValuesDtos.filter(item => item.totalComPisPasep > 0).length);
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false)
          });
      }
    }

  };

  const handleDelete = async (event) => {
    event.preventDefault();
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      }
      else {
        const confirmDelete = window.confirm("Você tem certeza que deseja deletar toso os arquivos dese periodo?");
        if (!confirmDelete) {
          return;
        }
        const partes = selectedPeriod.split('-');
        setLoading(true);
        try {
          const response = fetch(`${apiHostArq}/arquivos/s3?ano=${partes[0]}&mes=${partes[1]}&onsCode=${onsCode}`
            , {
              method: "DELETE",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${user.token}`,
                'Access-Control-Allow-Origin': '*'
              }
            }
          );

          if (!response.ok) {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return;
            } else {
              alert('Erro ao gerar o arquivo. Por favor, tente novamente.');
              return;
            }
          }
          const taskId = await response.json();
          console.log(taskId);
          const checkTaskStatus = async () => {
            const statusResponse = await fetch(`${apiHostArq}/job/status?id=${taskId}`, {
              headers: {
                "Authorization": `Bearer ${user.token}`,
              },
            });

            if (!statusResponse.ok) {
              throw new Error('Erro ao verificar o status da tarefa.');
            }

            const { status } = await statusResponse.json();

            if (status === 'FINALIZADO') {
              setLoading(false);
              navigate('/arquivos-sistema');
            } else if (status === 'FALHA') {
              alert('Erro ao gerar o arquivo. Por favor, tente novamente.');
              setLoading(false);
            } else {
              setTimeout(checkTaskStatus, 5000);
            }
          };
          checkTaskStatus();
        } catch (error) {
          alert('Erro ao processar o download. Por favor, tente novamente.');
          setLoading(false);
        }
      }
    }
  };

  const handleDownload = async (event) => {
    event.preventDefault();
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (!user || !user.profiles || !(user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      } else {
        const partes = selectedPeriod.split('-');
        setLoading(true);
        try {
          const response = await fetch(`${apiHostArq}/arquivos/file-generate/zip?ano=${partes[0]}&mes=${partes[1]}&onsCode=${onsCode}&cnpj=${ownerCompany}`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            }
          });

          if (!response.ok) {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return;
            } else {
              alert('Erro ao gerar o arquivo. Por favor, tente novamente.');
              return;
            }
          }

          const taskId = await response.json();
          console.log(taskId);
          const checkTaskStatus = async () => {
            const statusResponse = await fetch(`${apiHostArq}/job/status?id=${taskId}`, {
              headers: {
                "Authorization": `Bearer ${user.token}`,
              },
            });

            if (!statusResponse.ok) {
              throw new Error('Erro ao verificar o status da tarefa.');
            }

            const { status } = await statusResponse.json();

            if (status === 'FINALIZADO') {
              const downloadUrl = `${apiHostArq}/arquivos/file-generate/download?arquivo=${taskId}`;
              const link = document.createElement('a');
              link.download = `documentos-${onsCode}-${format(new Date(), 'yyyy-MM-dd-HH-mm-ss')}.zip`;


              const xhr = new XMLHttpRequest();
              xhr.open("GET", downloadUrl);
              xhr.setRequestHeader("Authorization", `Bearer ${user.token}`);
              xhr.responseType = 'blob';
              xhr.onload = function () {
                const url = window.URL.createObjectURL(xhr.response);
                link.href = url;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                window.URL.revokeObjectURL(url);
              };
              xhr.send();

              setLoading(false);
              navigate('/arquivos-sistema');
            } else if (status === 'FALHA') {
              alert('Erro ao gerar o arquivo. Por favor, tente novamente.');
              setLoading(false);
            } else {
              setTimeout(checkTaskStatus, 5000);
            }
          };
          checkTaskStatus();
        } catch (error) {
          alert('Erro ao processar o download. Por favor, tente novamente.');
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    const storedPeriod = localStorage.getItem('setSelectedPeriod');
    const storedStatus = localStorage.getItem('setSelectedStatusFile');

    if (storedPeriod) {     
      setSelectedPeriod(storedPeriod);
      setEnabledButton(true);
    }


    if (storedPeriod) {
      setSelectedStatus(storedStatus);
      setEnabledButton(true);
    }

    const fetchInitialData = (user) => {
      const periodParts = storedPeriod ? storedPeriod.split('-') : [`${new Date().getFullYear()}`, `${new Date().getMonth() + 1}`];
      setLoading(true);

      fetch(`${apiHost}/service/avc?ano=${periodParts[0]}&mes=${periodParts[1]}&numeroOns=${onsCode}&status`, {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
        .then(response => {
          if (response.status === 401) {
            alert('Sessão expirada. Por favor, faça login novamente.');
            navigate('/');
            return null;
          }
          return response.json();
        })
        .then(data => {
          setValores(data.avcValuesDtos.filter(item =>
            (selectedStatus === 'SEMBOLETOS' && (!item.invoice || !item.invoice.ticketDtoList)) ||
            (selectedStatus === 'SEMXML' && (!item.invoice || !item.invoice.nfeDto || !item.invoice.nfeDto.hasXml)) ||
            (selectedStatus === 'SEMDANFE' && (!item.invoice || !item.invoice.nfeDto || !item.invoice.nfeDto.hasPdf)) ||
            (selectedStatus === 'TODOS' && item)
          ));

          const hasInvoice = data.avcValuesDtos.reduce((acc, item) => {
            if (item.totalComPisPasep > 0 && item.invoice && item.invoice.nfeDto && item.invoice.nfeDto.hasXml) {
              acc.hasInvoiceXml++;
            }
            if (item.totalComPisPasep > 0 && item.invoice && item.invoice.nfeDto && item.invoice.nfeDto.hasPdf) {
              acc.hasInvoicePdf++;
            }
            if (item.totalComPisPasep > 0 && item.invoice && item.invoice.ticketDtoList) {
              acc.hasInvoiceTicket++;
            }
            if (item.totalComPisPasep > 0 && item.invoice) {
              acc.hasInvoice++;
            } else if (item.totalComPisPasep > 0) {
              acc.hasNoInvoice++;
            }
            return acc;
          }, { hasInvoicePdf: 0, hasNoInvoice: 0, hasInvoiceXml: 0, hasInvoiceTicket: 0, hasInvoice: 0 });

          setHasInvoice(hasInvoice);
          setItemsWithRedeBasica(data.avcValuesDtos.filter(item => item.totalComPisPasep > 0).length);
        })
        .catch(error => console.error(error))
        .finally(() => setLoading(false));
    };

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (user && (user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTO'))) {
        fetchInitialData(user);
      } else {
        navigate(`/404`);
      }
    }
  }, [apiHost, navigate, onsCode, selectedStatus]); // <-- Dependência vazia para executar apenas uma vez no carregamento inicial


  const columns = [
    { field: "idUsuaria", headerName: "Codigo Ons" },
    {
      field: "transmissoras",
      headerName: "Usuário",
      headerAlign: 'center',
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.5,
      headerAlign: 'center',
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          {params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5')}
        </Typography>
      ),
    },
    {
      field: "valorParcela1",
      headerName: "Xml",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.invoice
            && params.row.invoice.nfeDto
            && params.row.invoice.nfeDto.hasXml ?
            <FaRegCheckCircle color={colors.greenAccent[500]} />
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "valorParcela2",
      headerName: "Danfe",
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.invoice
            && params.row.invoice.nfeDto
            && params.row.invoice.nfeDto.hasPdf ?
            <FaRegCheckCircle color={colors.greenAccent[500]} />
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "valorParcela3",
      headerName: "Boleto",
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%'
        }} >
          {params.row.invoice
            && params.row.invoice.ticketDtoList
            && params.row.invoice.ticketDtoList.length > 0 ? <FaRegCheckCircle color={colors.greenAccent[500]} />
            : <IoIosHourglass color="red" />}
        </Typography>
      ),
    },
    {
      field: "totalComPisPasep",
      headerName: "Valor Avc",
      flex: 1,
      renderCell: (params) => (
        <Typography color={colors.greenAccent[500]}>
          {params.row.totalOns !== null ? params.row.totalOns.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) : (0.0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.row.totalOns === 0  ? "warning" : params.row.statusCarregamento === "CARREGADO" ? "success" : "error"}
          onClick={() => handleClick(params.row)}
        >
          {params.row.totalOns === 0  ?  "SEM FATURA" : params.row.statusCarregamento === "CARREGADO" ? "Carregado " : "PENDENTE"}
        </Button>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Grid container spacing={2}>
        <Grid item xs={2}>
          <Header title="" subtitle="Gerenciamento - Carga de Arquivos de Faturamento" />
        </Grid>
        <Grid item xs={2}>
          <FormControl>
            <Select
              label="Período Contábil"
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={handlePeriodChange}
            >
              <MenuItem value="0">Período Contábil ...</MenuItem>
              {years.slice().sort((a, b) => b - a).map(year => (
                months.map(month => (
                  <MenuItem key={`${year}-${month.value}`} value={`${year}-${month.value}`}>
                    {`${month.label} ${year}`}
                  </MenuItem>
                ))
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <FormControl>
            <Select
              labelId="status-label"
              id="status"
              value={selectedStatus}
              onChange={handleStatusChange}
            >
              {statusCarga.map(status => (
                <MenuItem key={`${status.value}`} value={`${status.value}`}>
                  {`${status.label} `}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: colors.redAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "10px"
            }}
            disabled={!enabledButton}
            onClick={handleDelete}
          >
            <DeleteForeverOutlined sx={{ mr: "10px" }} onChange={handleDelete} />
            Deletar
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "10px"
            }}
            disabled={!enabledButton}
            onClick={handleDownload}
          >
            <DownloadingOutlined sx={{ mr: "10px" }} onChange={handleDownload} />
            Download
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "10px"
            }}
            disabled={!enabledButton}
            onClick={handleSyncAvc}
          >
            <CloudSyncRounded sx={{ mr: "10px" }} onChange={handleSyncAvc} />
            Sync {progress}
          </Button>
        </Grid>
      </Grid>


      <Box display="flex" alignItems="center">
        <Box mr={2}>
          <Grid
            xs={6}
            sm={3}
            lg={1}
          >
            <OverviewTasksProgressTotal
              sx={{ height: '100%' }}
              value={hasInvoice.hasInvoiceXml}
              title="XML Carregados"
            />
          </Grid>
        </Box>
        <Box mr={2}>
          <Grid
            xs={6}
            sm={3}
            lg={1}
          >
            <OverviewTasksProgressTotal
              sx={{ height: '100%' }}
              value={hasInvoice.hasInvoicePdf}
              title="Danfe Carregados"
            />
          </Grid>
        </Box>
        <Box mr={2}>
          <Grid
            xs={6}
            sm={3}
            lg={1}
          >
            <OverviewTasksProgressTotal
              sx={{ height: '100%' }}
              value={hasInvoice.hasInvoiceTicket}
              title="Boletos Carregados"
            />
          </Grid>
        </Box>
        <Box mr={2}>
          <Grid
            xs={6}
            sm={3}
            lg={1}
          >
            <OverviewTasksProgressTotal
              sx={{ height: '100%' }}
              value={hasInvoice.hasInvoice}
              title="Total Emitido"
            />

          </Grid>
        </Box>
        <Box mr={2}>
          <Grid
            xs={6}
            sm={3}
            lg={1}
          > <OverviewTasksProgressTotal
              sx={{ height: '100%' }}
              value={itemsWithRedeBasica - hasInvoice.hasInvoice}
              title="Total Aguardando"
            />
          </Grid>
        </Box>
      </Box>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .no-border-bottom": {
            borderBottom: "none !important",
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={valores}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box >
  );
};

export default ArquivosSite;
