import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import {
  InputLabel,
  Box, Button, Select,
  MenuItem, FormControl, Backdrop,
  CircularProgress, Typography, Grid
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../theme";
import Header from "../../components/Header";
import { useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import { format } from 'date-fns';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Inadimplencia = () => {
  const apiHost = process.env.REACT_APP_API_URL_FIN || "http://localhost:8010";
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => 2021 + index);
  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const getLastDayOfMonth = (date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    return new Date(year, month + 1, 0);
  };
  const [valores, setValores] = useState([]);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);


  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState(getLastDayOfMonth(new Date()));
  const [selectedPeriod, setSelectedPeriod] = useState(`0`);

  const handleDateChange = (date) => {
    const dateSender = date;
    setSelectedDate(dateSender);
    localStorage.setItem('setSelectedDate', dateSender);
    fetchData(dateSender, selectedPeriod);
  };

  const handlePeriodChange = (e) => {
    const period = e.target.value;
    setSelectedPeriod(period);
    localStorage.setItem('setSelectedPeriod', period);
    fetchData(selectedDate,period);
  };

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  const fetchData = (date, period) => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (!user || !user.profiles || !(user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTO'))) {
        navigate(`/404`);
      } else {
        const formattedDate = format(date, 'yyyy-MM-dd');
        const partes = period.split('-');

        setLoading(true);
        fetch(`${apiHost}/service/avc/debits?ano=${partes[0]}&mes=${partes[1]}&dataSimulacao=${formattedDate}`, {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${user.token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
          .then(response => {
            if (response.status === 401) {
              alert('Sessão expirada. Por favor, faça login novamente.');
              navigate('/');
              return null;
            }
            return response.json();
          })
          .then(data => {
            setValores(data.avcDebitDtos.map(item => item));
          })
          .catch(error => console.error(error))
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  useEffect(() => {
    const storedPeriod = localStorage.getItem('setSelectedPeriod');
    const storedDate = localStorage.getItem('setSelectedDate');
    if (storedPeriod) setSelectedPeriod(storedPeriod);
    if (storedPeriod) setSelectedDate(storedDate);

    const fetchInitialData = (user) => {
      const periodParts = storedPeriod ? storedPeriod.split('-') : [`${new Date().getFullYear()}`, `${new Date().getMonth() + 1}`];
      const formattedDate = format(getLastDayOfMonth(new Date()), 'yyyy-MM-dd');
      setLoading(true);
      fetch(`${apiHost}/service/avc/debits?ano=${periodParts[0]}&mes=${periodParts[1]}&dataSimulacao=${formattedDate}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${user.token}`,
          'Access-Control-Allow-Origin': '*'
        },
      })
      .then(response => {
        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return null;
        }
        setLoading(false);
        return response.json();
      })
      .then(response => {
        if (response.status === 401) {
          alert('Sessão expirada. Por favor, faça login novamente.');
          navigate('/');
          return null;
        }
        return response.json();
      })
      .then(data => {
        setValores(data.avcDebitDtos.map(item => item));
      })
      .catch(error => console.error(error))
      .finally(() => {
        setLoading(false);
      });
    };

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey);
      if (user && (user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTO'))) {
        fetchInitialData(user);
      } else {
        navigate(`/404`);
      }
    }
  }, [apiHost, navigate, secretKey]);

  const handleClick = (item) => {
    navigate(`/invoices-detail?cnpj=${item.cnpj}&numeroOns=${item.idUsuaria}`);
  };

  const columns = [
    { field: "idUsuaria", headerName: "Usuária" },
    { field: "codigoNovoRadar", headerName: "Codigo Radar" },
    {
      field: "transmissoras",
      headerName: "Usuário",
      flex: 2,
      cellClassName: "name-column--cell",
    },
    {
      field: "cnpj",
      headerName: "CNPJ",
      flex: 1.5,
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Typography >
          { params.row.cnpj ? params.row.cnpj.replace(
            /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2}).*/,
            '$1.$2.$3/$4-$5') : "00.000.000/0000-00"}
        </Typography>
      ),
    },
    {
      field: "parcelaValor",
      headerName: "Valor Devido",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.status === 'Aguardando' ? colors.blueAccent[500] : params.row.status === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
          {params.row.parcelaValor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "valorCorrigido",
      headerName: "Valor Corrigido",
      flex: 1,
      renderCell: (params) => (
        <Typography color={params.row.status === 'Aguardando' ? colors.blueAccent[500] : params.row.status === 'Atraso' ? colors.redAccent[500] : colors.greenAccent[500]}>
          {params.row.valorCorrigido.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}
        </Typography>
      ),
    },
    {
      field: "diasAtraso",
      headerName: "Dias Atraso",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "periodoContabilInt",
      headerName: "Periodo Contabil",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => (
        <Button
          variant="contained"
          color={params.row.isNewUG ? "secondary" : params.row.status === 'Aguardando' ? "warning" : params.row.status === 'Atraso' ? "error" : "success"}
          onClick={() => handleClick(params.row)}
        >
          {params.row.status}
        </Button>
      ),
    },
  ];

  return (
    <Box m="30px">
       <Grid container spacing={4}>
        <Grid item xs={4}>
        <Header title="Inadimplência AVC" subtitle="Lista de Valores em atraso" />
        </Grid>
        <Grid item xs={3}>
        <FormControl variant="outlined" fullWidth>
              <InputLabel id="select-label">Escolha o Periodo</InputLabel>
              <Select
                labelId="period-label"
                id="period"
                value={selectedPeriod}
                onChange={handlePeriodChange}
                label="Escolha o Periodo"
              >
              <MenuItem value="0">Período Contábil ...</MenuItem>
                {years.slice().sort((a, b) => b - a).map(year => (
                  months.map(month => (
                    <MenuItem key={`${year}-${month.value}`} value={`${year}-${month.value}`}>
                      {month.label} de {year}
                    </MenuItem>
                  ))
                ))}
              </Select>
            </FormControl>
        </Grid>
        <Grid item xs={3}>
        <Button
              sx={{
                backgroundColor: colors.blueAccent[700],
                color: colors.grey[100],
                fontSize: "14px",
                fontWeight: "bold",
                padding: "10px 20px",

              }}
            >
              <DatePicker mr={2}
                style={{ backgroundColor: colors.blueAccent[700] }}
                locale="pt-BR"
                selected={selectedDate}
                onChange={(date) => handleDateChange(date)}
              />
            </Button>
        </Grid>
      </Grid>

      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box
        m="10px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={valores}
          columns={columns}
          components={{ Toolbar: GridToolbar }}
        />
      </Box>
    </Box>
  );
};

export default Inadimplencia;
