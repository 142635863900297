import { CheckCircleOutline, UploadFileOutlined } from '@mui/icons-material';
import {
  Backdrop,
  Box, Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, TableRow,
  useTheme,
  Grid
} from "@mui/material";
import React, { useRef, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from "../../components/Header";
import { tokens } from "../../theme";
import CryptoJS from 'crypto-js';
import { IoIosHourglass } from "react-icons/io";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Arquivos = () => {

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [enableUpload, setEnableUpload] = useState(false);
  const [uploadStatus, setUploadStatus] = useState({});
  const [uplodrs, setUplodrs] = useState({ size: 0, job: 0 });
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(false);
  const currentYear = new Date().getFullYear();
  const years = Array.from({ length: currentYear - 2021 + 1 }, (_, index) => 2021 + index);
  const secretKey = 'f&$v16dcGwV_8ZZX*oP7nqW=';
  const navigate = useNavigate();
  const fileInputRef = useRef(null);

  const getFromSession = (key) => {
    return sessionStorage.getItem(key);
  };

  const decryptPayload = (encryptedPayload, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedPayload, secretKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  };

  useEffect(() => {
    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)
      if (!user && !user.profiles
        && !(user.profiles.includes('ADMIN') || !user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      }
    }
    const storedPeriod = localStorage.getItem('setSelectedPeriod');
    if (storedPeriod) {
      setSelectedPeriod(storedPeriod);
      setEnableUpload(true);
    }
  }, [navigate]);

  const apiHost = process.env.REACT_APP_API_URL_ARQ || "http://localhost:8082";
  const ownerCompany = process.env.REACT_APP_API_URL_COMPANY_CNPJ || "24870139000405";
  const onsCode = process.env.REACT_APP_API_URL_COMPANY_ONS_CODE || "1257";

  const months = [
    { value: '01', label: 'Janeiro' },
    { value: '02', label: 'Fevereiro' },
    { value: '03', label: 'Março' },
    { value: '04', label: 'Abril' },
    { value: '05', label: 'Maio' },
    { value: '06', label: 'Junho' },
    { value: '07', label: 'Julho' },
    { value: '08', label: 'Agosto' },
    { value: '09', label: 'Setembro' },
    { value: '10', label: 'Outubro' },
    { value: '11', label: 'Novembro' },
    { value: '12', label: 'Dezembro' },
  ];

  const [selectedPeriod, setSelectedPeriod] = useState(`${years[0]}-${months[0].value}`);

  const handleUpload = async (event) => {
    setEnableUpload(false)
    if (loading) return;
    setLoading(true);
    event.preventDefault();

    const storedEncryptedPayload = getFromSession('session');
    if (storedEncryptedPayload) {
      const user = decryptPayload(storedEncryptedPayload, secretKey)

      if (!user || !user.profiles || !(user.profiles.includes('ADMIN') || user.profiles.includes('DOCUMENTOS'))) {
        navigate(`/404`);
      }

      const partes = selectedPeriod.split('-');

      for (const file of selectedFiles) {
        const formData = new FormData();
        formData.append('files', file);
        try {
          const response = await fetch(`${apiHost}/arquivos/extractor/upload?index=1&cnpj=${ownerCompany}&ano=${partes[0]}&mes=${partes[1]}&onsCode=${onsCode}`, {
            headers: {
              "Authorization": `Bearer ${user.token}`,
              'Access-Control-Allow-Origin': '*'
            },
            method: 'POST',
            body: formData
          });

          if (response.ok) {
            setUplodrs((prevUplodrs) => ({
              ...prevUplodrs,
              job: prevUplodrs.job + 1
            }));

            setUploadStatus((prevUploadStatus) => ({
              ...prevUploadStatus,
              [file.name]: 'Concluído'
            }));

          } else if (response.status === 401) {
            alert('Sessão expirada. Por favor, faça login novamente.');
            navigate('/');
            return null;
          } else {
            setUploadStatus((prevUploadStatus) => ({
              ...prevUploadStatus,
              [file.name]: 'NOK'
            }));
          }

        } catch (error) {
          setUploadStatus((prevUploadStatus) => ({
            ...prevUploadStatus,
            [file.name]: 'NOK'
          }));
        }
      }
      setLoading(false);
      toast.success('Processo de Upload Finalizado com Sucesso!');
    }
  };


  const handlePeriodChange = (e) => {
    const period = e.target.value;
    setSelectedPeriod(period);
    localStorage.setItem('setSelectedPeriod', period);
    setEnableUpload(true);
  };


  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    const sortedFiles = files.sort((a, b) => {
      if (a.name.endsWith('.xml') && !b.name.endsWith('.xml')) {
        return -1;
      }
      if (!a.name.endsWith('.xml') && b.name.endsWith('.xml')) {
        return 1;
      }
      return 0;
    });

    setUplodrs({ size: sortedFiles.length, job: 0 });
    setSelectedFiles(sortedFiles);
    setUploadStatus({});
  };


  return (
    <Box m="30px">
      <Grid container spacing={1}>
        <Grid item xs={5}>
          <Header title="Arquivos de Faturamento AVC" subtitle="Lista de arquivos a Receber" />
        </Grid>
        <Grid item xs={2}>
          <FormControl>
            <Select
              labelId="period-label"
              id="period"
              value={selectedPeriod}
              onChange={handlePeriodChange}
            >
              <MenuItem value="">Período Contábil ...</MenuItem>
              {years.slice().sort((a, b) => b - a).map(year => (
                months.map(month => (
                  <MenuItem key={`${year}-${month.value}`} value={`${year}-${month.value}`}>
                    {`${month.label} ${year}`}
                  </MenuItem>
                ))
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={2}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginRight: "10px"
            }}
            disabled={!enableUpload}
            onClick={() => fileInputRef.current.click()}
          >
            <input
              type="file"
              directory=""
              webkitdirectory=""
              ref={fileInputRef}
              multiple
              onChange={handleFileChange}
              accept=".zip,.pdf,.xml"
              style={{ display: 'none' }}
            />
            Selecionar Arquivos
          </Button>
        </Grid>
        <Grid item xs={3}>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
              marginLeft: "10px"
            }}
            disabled={!enableUpload}
            onClick={handleUpload}
          >
            <UploadFileOutlined sx={{ mr: "10px" }} onChange={handleUpload} />
            Upload Documentos  ({uplodrs.job} - {uplodrs.size})   {uplodrs.size > 0 ? ((uplodrs.job / uplodrs.size) * 100).toFixed(2) : 0}%
          </Button>
        </Grid>
      </Grid>
      <Box mt="20px">
        {selectedFiles.length > 0 && (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Nome do Arquivo</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Progresso</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedFiles.map((file) => (
                  <TableRow key={file.name}>
                    <TableCell>{file.name}</TableCell>
                    <TableCell>
                      {uploadStatus[file.name] || 'Aguardando Envio'}
                    </TableCell>
                    <TableCell>
                      {uploadStatus[file.name] === 'NOK' ?
                        <CheckCircleOutline fontSize="large" color="error" /> :
                        uploadStatus[file.name] === 'Concluído' ?
                          <CheckCircleOutline fontSize="large" color="success" /> : <IoIosHourglass color="red" />
                      }
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>

      {loading && (
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      <ToastContainer />
    </Box >
  );
};

export default Arquivos;
